import moment from 'moment'

import { CaseFormFields } from '../../../components/forms/CaseForm'

export const getPayloadFromFieldsValue = (fieldsValue: CaseFormFields) => ({
  doctor: fieldsValue.doctor,
  title: fieldsValue.title,
  pageTitle: fieldsValue.pageTitle,
  description: fieldsValue.description,
  alias: fieldsValue.alias,
  content: fieldsValue.content,
  video: fieldsValue.video,
  category: fieldsValue.category,
  location: fieldsValue.location,
  images: fieldsValue.images?.map(image => image?.response?.url),
  thumbnailImage: fieldsValue.thumbnailImage?.[0]?.response?.url,
  publishedDate: moment(fieldsValue.publishedDate).toDate()
})

import { DoctorFormFields } from '../../../components/forms/DoctorForm'

export const getPayloadFromFieldsValue = (fieldsValue: DoctorFormFields) => ({
  name: fieldsValue.name,
  image: fieldsValue.image?.[0]?.response?.url,
  location: fieldsValue.location?.map(location => location),
  title: fieldsValue.title,
  strength: fieldsValue.strength,
  experience: fieldsValue.experience,
  elite: fieldsValue.elite,
  commend: fieldsValue.commend,
  order: fieldsValue.order
})

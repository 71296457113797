import { Layout, Menu } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import LogoutButton from '../LogoutButton'
import LinkWithConfirm from '../LinkWithConfirm'
const Content = styled(Layout.Content)`
  padding: ${space.m}px;
`

interface DashboardLayoutProps {
  isDirty: boolean
}

const DashboardLayout: FC<DashboardLayoutProps> = props => {
  const {isDirty} = props
  return (
    <Layout>
      <Layout.Header>
        <Menu theme='dark' mode='horizontal'>
          <Menu.Item key='logo'>
            <LinkWithConfirm to='/dashboard' isDirty={isDirty}>Sunlight 網站後台</LinkWithConfirm>
          </Menu.Item>
          <Menu.SubMenu key='cases' title='口碑見證'>
            <Menu.Item key='cases-list'>
              <LinkWithConfirm to='/dashboard/cases' isDirty={isDirty}>列表</LinkWithConfirm>
            </Menu.Item>
            <Menu.Item key='cases-create'>
              <LinkWithConfirm to='/dashboard/cases/create' isDirty={isDirty}>新增</LinkWithConfirm>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key='news' title='最新衛教'>
            <Menu.Item key='news-list'>
              <LinkWithConfirm to='/dashboard/news' isDirty={isDirty}>列表</LinkWithConfirm>
            </Menu.Item>
            <Menu.Item key='news-create'>
              <LinkWithConfirm to='/dashboard/news/create' isDirty={isDirty}>新增</LinkWithConfirm>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key='doctors' title='醫療團隊'>
            <Menu.Item key='doctors-list'>
              <LinkWithConfirm to='/dashboard/doctors' isDirty={isDirty}>列表</LinkWithConfirm>
            </Menu.Item>
            <Menu.Item key='doctors-create'>
              <LinkWithConfirm to='/dashboard/doctors/create' isDirty={isDirty}>新增</LinkWithConfirm>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key='contactUs'>
            <LinkWithConfirm to='/dashboard/comments' isDirty={isDirty}>聯絡我們</LinkWithConfirm>
          </Menu.Item>
          <Menu.SubMenu key='banners' title='首頁橫幅'>
            <Menu.Item key='banners-list'>
              <LinkWithConfirm to='/dashboard/banners' isDirty={isDirty}>列表</LinkWithConfirm>
            </Menu.Item>
            <Menu.Item key='banners-create'>
              <LinkWithConfirm to='/dashboard/banners/create' isDirty={isDirty}>新增</LinkWithConfirm>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key='logout' danger>
            <LogoutButton isDirty={isDirty} />
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Content>{props.children}</Content>
    </Layout>
  )
}

export default DashboardLayout

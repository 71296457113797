import { gql, useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, Popconfirm, Row, Table, Typography, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StringParam } from 'use-query-params'

import {
  CommentsQuery,
  DashboardCommentListDocs,
  DashboardCommentListQuery,
  DashboardCommentListQueryVariables,
  RemoveCommentMutation,
  RemoveCommentMutationVariables,
  UpdateCommentMutation,
  UpdateCommentMutationVariables
} from '../../../types/types'
import { getAntdPagination } from '../../../utils/antd'
import useFormQuery from '../../../utils/hooks/useFormQuery'
import useTableQuery from '../../../utils/hooks/useTableQuery'
import CommentStatusLabel from './CommentLabel'
import EditableFiled from './EditableFiled'
import LocationLabel from './LocationLabel'

const commentListQuery = gql`
  query DashboardCommentList($query: CommentsQuery, $page: Int, $limit: Int, $sort: String) {
    comments(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        status
        created
        name
        phone
        line
        location
        internalNote
        content
      }
      page
      limit
      total
    }
  }
`

const removeComment = gql`
  mutation RemoveComment($id: ID!) {
    removeComment(id: $id) {
      id
    }
  }
`

const updateComment = gql`
  mutation updateComment($id: ID!, $payload: UpdateCommentInput!) {
    updateComment(payload: $payload, id: $id) {
      id
      status
      internalNote
    }
  }
`

type CommentListFilterType = Pick<CommentsQuery, 'name'>
type CommentListSortField = 'created'

const WordBreakWrapper = styled.div`
  word-break: break-all;
`

const formInput = {
  name: StringParam
}

interface CommentSearchFormFields {
  name?: string
}

interface CommentSearchFormProps {
  initialValues: CommentSearchFormFields
  handleSearch: (form: FormInstance<CommentSearchFormFields>) => void
  handleReset: () => void
}

const CommentSearchForm: FC<CommentSearchFormProps> = props => {
  const { initialValues, handleSearch, handleReset } = props
  const [form] = Form.useForm<CommentSearchFormFields>()

  return (
    <Form form={form} initialValues={initialValues} layout='inline'>
      <Form.Item name='name' label='姓名'>
        <Input onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Button onClick={handleReset}>重置</Button>
    </Form>
  )
}

const CommentList: FC<RouteComponentProps> = () => {
  const { formQuery, handleFormChange, handleFormReset } = useFormQuery(formInput)
  const { tableQuery, handleTableChange, handleTableReset, cursor } = useTableQuery<CommentListFilterType, CommentListSortField>({ limit: 10, sort: '-created' })

  const initialValues = {
    name: formQuery.name || ''
  }

  const { data, loading, refetch } = useQuery<DashboardCommentListQuery, DashboardCommentListQueryVariables>(commentListQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: {
        ...initialValues
      },
      ...cursor
    }
  })
  const [remove] = useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(removeComment)
  const [update] = useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(updateComment)

  const comments = data?.comments?.docs
  const pagination = data?.comments ? getAntdPagination(data?.comments) : false

  const handleRemove = (commentId: string) => {
    remove({
      variables: { id: commentId },
      update: async (cache, { data }) => {
        if (data?.removeComment) {
          message.info('已刪除留言')
          refetch()
        }
      }
    })
  }

  const columns: ColumnsType<DashboardCommentListDocs> = [
    {
      title: '#',
      width: '10px',
      render: (text, record, index) => <div>{index + 1}</div>
    },
    {
      title: '新增日期',
      key: 'created',
      width: '8%',
      dataIndex: 'created',
      sortOrder: tableQuery.sort?.columnKey === 'created' ? tableQuery.sort.order : null,
      sorter: (a, b) => (moment(a.created).isBefore(moment(b.created)) ? -1 : 1),
      render: (text, record) => <>{moment(record.created).format('YYYY/MM/DD HH:mm:ss')}</>
    },
    {
      title: '預約診所',
      dataIndex: 'location',
      key: 'location',
      width: '6%',
      fixed: 'left',
      render: value => {
        return <LocationLabel value={value} />
      }
    },
    {
      title: '姓名',
      key: 'name',
      width: '8%',
      dataIndex: 'name'
    },
    {
      title: '電話',
      key: 'phone',
      width: '10%',
      dataIndex: 'phone'
    },
    {
      title: 'LineID',
      key: 'line',
      width: '10%',
      dataIndex: 'line'
    },
    {
      title: '內容',
      key: 'content',
      dataIndex: 'content'
    },
    {
      title: '狀態更新',
      width: '114px',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (value, record) => {
        const updateStatus = value => {
          update({ variables: { payload: { status: value }, id: record.id } })
          message.success('狀態更新成功')
        }
        return <CommentStatusLabel value={value} handleSubmit={updateStatus} />
      }
    },
    {
      title: '客服備註',
      width: '5%',
      align: 'center',
      dataIndex: 'internalNote',
      key: 'internalNote',
      render: (value, record) => {
        const updateInternalNote = value => {
          update({ variables: { payload: { internalNote: value }, id: record.id } })
          message.success('已更新備註')
        }
        return (
          <WordBreakWrapper>
            <EditableFiled value={value} handleSubmit={updateInternalNote} />
          </WordBreakWrapper>
        )
      }
    },
    {
      title: '操作',
      key: 'id',
      width: '30px',
      align: 'center',
      render: (text, record) => {
        const commentId = record.id

        const handleConfirm = () => {
          handleRemove(commentId)
        }

        return (
          <Popconfirm title='確定刪除留言？' onConfirm={handleConfirm}>
            <Button type='primary' danger size='small' style={{ fontSize: '12px' }}>
              刪除
            </Button>
          </Popconfirm>
        )
      }
    }
  ]

  const handleSearch = (form: FormInstance<CommentSearchFormFields>) => {
    handleFormChange(form.getFieldsValue())
  }
  const handleReset = () => {
    handleFormReset()
    handleTableReset()
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Typography.Title>留言列表</Typography.Title>
        <CommentSearchForm initialValues={initialValues} handleSearch={handleSearch} handleReset={handleReset} />
      </Row>
      <Table
        rowKey='id'
        loading={loading}
        dataSource={comments}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
        style={{ width: '100%', overflow: 'auto' }}
      />
    </>
  )
}

export default CommentList

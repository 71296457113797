import { PaginationProps } from 'antd/lib/pagination'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { mergeDeepRight, pipe, prop, takeLast } from 'ramda'

/** ----- Table ----- */
/** 取得 table 分頁資訊 */
export const getAntdPagination = ({ page, total, limit }: { page: number; total: number; limit: number }): PaginationProps => ({
  current: page || 1,
  total,
  pageSize: limit,
  showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} 項結果`
})

/** ----- Upload ----- */
/** 從事件中取出檔案 */
export const getFileListFromEvent = prop('fileList')

/** 僅取出最後上傳的檔案 */
export const takeLastFromFileList = pipe<UploadChangeParam, UploadChangeParam['fileList'], UploadChangeParam['fileList']>(getFileListFromEvent, takeLast(1))

/** 使用 url 建立 antd file 物件 */
export const getFileObjectFromUrl = (url: string, info?: Partial<UploadFile>): UploadFile =>
  mergeDeepRight(
    {
      uid: 'uid',
      name: 'name',
      size: 0,
      type: '',
      url,
      response: { url }
    },
    info
  )

import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Skeleton } from 'antd'
import { Link, useIntl } from 'gatsby-plugin-intl'
import htmlReactParser from 'html-react-parser'
import moment from 'moment'
import { complement, filter, isEmpty, isNil, map, take } from 'ramda'
import React, { FC, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import NewsCoverImg from '../../assets/static/news_cover.png'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import Button from '../../components/Button'
import { News } from '../../components/Cards'
import Carousel from '../../components/Carousel'
import { GridItem, GridLayout } from '../../components/Grid'
import { ThemeProvider } from '../../components/Layout'
import Seo from '../../components/Seo'
import { ClientPageContentTitle } from '../../components/Titles/ContentTitle'
import { DefaultText } from '../../constants/color'
import { space } from '../../constants/length'
import { articleStyle } from '../../constants/style'
import { textSize } from '../../constants/text'
import { viewport } from '../../constants/viewport'
import { NewsAndNewsListQuery, NewsAndNewsListQueryVariables } from '../../types/types'
import { MetaDescription, MetaTitle } from '.'

const newsAndNewsListQuery = gql`
  query NewsAndNewsList($id: ID!, $query: NewsQuery, $page: Int, $limit: Int, $sort: String) {
    news(id: $id) {
      id
      pageTitle
      description
      content
      publishedDate
      alias
      images
      thumbnailAlt
      title
      video
    }
    newsList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        publishedDate
        title
        alias
        thumbnailImage
        thumbnailAlt
        description
      }
    }
  }
`

const desktopContentWith = 1140
const tabletContentWith = 720
const mobileContentWith = 355
const NewsWrapper = styled.div``
const StyledPageContent = styled.div`
  margin: 0 auto 84px auto;
  @media (min-width: ${viewport.desktop}px) {
    max-width: ${desktopContentWith}px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    max-width: ${tabletContentWith}px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: ${mobileContentWith}px;
  }
`

const ContentWrapper = styled.div`
  padding: 0 ${space.s}px;

  ${NewsWrapper} + ${NewsWrapper} {
    margin-top: ${space.s}px;
  }
`
const BreadcrumbBarWrapper = styled.div`
  margin: ${space.l}px 0;
`
const StyledContentTitle = styled(ClientPageContentTitle)`
  margin-bottom: ${space.l}px;
`
const Title = styled.h1`
  font-size: ${textSize.h2Web}px;
  font-weight: 500;
  margin-bottom: ${space.m}px;
  color: ${DefaultText};
`
const Date = styled.div`
  font-size: ${textSize.body}px;
  margin-bottom: ${space.l}px;
`
const CarouselWrapper = styled.div<{ width: number }>`
  margin: 0 auto;
  width: ${props => props.width}px;
  overflow: hidden;
`
const NewsContent = styled.div`
  ${articleStyle}
  margin-top: ${space.l}px;
  margin-bottom: ${space.xxl * 2}px;
`
const IframeContainer = styled.div`
  overflow: hidden;
  position: relative;
  /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

const ListButtonWrapper = styled.div`
  margin-top: ${space.xxl}px;
`

const isNotEmpty = complement(isEmpty)

const otherNewsCount = 3

type Props = RouteComponentProps<{ newsId: string }>

const NewsPage: FC<Props> = props => {
  const { newsId } = props

  if (!newsId) {
    return null
  }

  const { formatMessage } = useIntl()
  const [pageContentWidth, setPageContentWidth] = useState(0)
  const pageContentRef = useRef<HTMLDivElement>(null)

  const addLazyLoading = () => {
    const container = document.getElementById('content')
    const images = container?.getElementsByTagName('img') as HTMLCollectionOf<HTMLImageElement>

    for (let i = 0; i < images.length; i++) {
      const image = images[i]
      image.setAttribute('loading', 'lazy')
    }
  }

  const { data, loading } = useQuery<NewsAndNewsListQuery, NewsAndNewsListQueryVariables>(newsAndNewsListQuery, {
    variables: {
      id: newsId,
      limit: otherNewsCount + 1,
      sort: '-updated'
    }
  })

  useLayoutEffect(() => {
    if (pageContentRef.current?.clientWidth) {
      setPageContentWidth(pageContentRef.current?.clientWidth)
    }
    addLazyLoading()
  }, [data])

  if (loading) {
    return <Skeleton paragraph active />
  }

  const pageData = data?.news
  const rawOtherNews = data?.newsList
  const newsAlias = pageData?.alias

  if (!pageData || !rawOtherNews) {
    return null
  }

  const otherNews = take(
    2,
    filter(news => (news.alias ? news.alias !== newsAlias && news.id !== newsId : news.id !== newsId), rawOtherNews?.docs ?? [])
  )
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.news' }),
      path: '/news'
    },
    {
      label: pageData.title,
      path: ''
    }
  ]

  return (
    <>
      <Seo title={pageData.pageTitle || pageData.title || MetaTitle} metaDescription={pageData.description || MetaDescription} />
      <StyledPageContent>
        <ThemeProvider>
          <GridLayout templateColumns={['100%', '100%', 'repeat(12, 1fr)']}>
            <GridItem column={['auto', 'auto', '3 / span 8']}>
              <ContentWrapper ref={pageContentRef} className='content'>
                <BreadcrumbBarWrapper>
                  <BreadcrumbBar routes={routes} />
                </BreadcrumbBarWrapper>
                <StyledContentTitle>{formatMessage({ id: 'pageTitle.news' })}</StyledContentTitle>
                <Title>{pageData.title}</Title>
                <Date>{moment(pageData.publishedDate).format('YYYY.MM.DD')}</Date>
                {pageData.video && <IframeContainer dangerouslySetInnerHTML={{ __html: pageData.video || '' }} title='' />}
              </ContentWrapper>
            </GridItem>
          </GridLayout>
        </ThemeProvider>
        {/* Carousel 不能放在 grid 排版中，故這邊 PageContent 切成兩半 */}
        {isNotEmpty(pageData.images) && (
          <CarouselWrapper width={pageContentWidth}>
            <Carousel>
              {map(
                image => (
                  <img key={image} src={image} alt={pageData.thumbnailAlt} />
                ),
                pageData.images ?? []
              )}
            </Carousel>
          </CarouselWrapper>
        )}
        <ThemeProvider>
          <GridLayout templateColumns={['100%', '100%', 'repeat(12, 1fr)']}>
            <GridItem column={['auto', 'auto', '3 / span 8']}>
              <ContentWrapper id='content'>
                {pageData.content && <NewsContent>{htmlReactParser(pageData.content)}</NewsContent>}
                <StyledContentTitle>{formatMessage({ id: 'sectionTitle.otherNews' })}</StyledContentTitle>
                <GridLayout templateColumns={['100%', '1fr 1fr']} columnGap={['unset', `${space.xxl}px`]} rowGap={[`${space.l}px`, 'unset']}>
                  {map(
                    ({ id, title, publishedDate, alias, description, thumbnailImage, thumbnailAlt }) => (
                      <News
                        key={id}
                        title={title}
                        date={moment(publishedDate).format('YYYY.MM.DD')}
                        newsId={id}
                        alias={alias}
                        description={description}
                        thumbnailImage={!isNil(thumbnailImage) ? thumbnailImage : NewsCoverImg}
                        thumbnailAlt={thumbnailAlt || ''}
                      />
                    ),
                    otherNews
                  )}
                </GridLayout>
                <ListButtonWrapper>
                  <Link to='/news'>
                    <Button label='文章列表 &#9658;' type='secondary' />
                  </Link>
                </ListButtonWrapper>
              </ContentWrapper>
            </GridItem>
          </GridLayout>
        </ThemeProvider>
      </StyledPageContent>
    </>
  )
}

export default NewsPage

import { SortOrder, SorterResult } from 'antd/lib/table/interface'
import moment from 'moment'
import { all, always, any, compose, either, flatten, identity, ifElse, isEmpty, isNil, join, map, reduce, reject, replace, split, toPairs, values } from 'ramda'
import { QueryParamConfig } from 'use-query-params'

const isEmptyOrNil = (value): value is null | undefined => either(isEmpty, isNil)(value)

const keyValueSeparator = '-'
const valuesSeparator = ','
const entrySeparator = '&'

/**
 * Encodes antd table filter
 *
 * @template FilterType
 * @param {Record<keyof FilterType, string[]>} filters
 * @returns {(string | undefined)} Example: `"filters"="category-feature_status-pending"`
 */
const encodeFilters = <FilterType>(filters: Record<keyof FilterType, string[]>): string | undefined => {
  if (!filters || !flatten(values(filters)).length) {
    return undefined
  }

  return compose<Record<keyof FilterType, string[]>, [string, string[]][], [keyof FilterType, string[]][], string[], string>(
    join(entrySeparator),
    map(([key, value]) => `${key}${keyValueSeparator}${join(valuesSeparator, value)}`),
    // @ts-ignore
    reject(([key, value]) => isEmpty(value) || isNil(value)),
    toPairs
  )(filters)
}

/**
 * Decodes url to FilterType
 * Return array of string if filter is multiple
 *
 * @template FilterType
 * @param {(string | string[] | null | undefined)} input
 * @returns {(FilterType | undefined)}
 */
const decodeFilters = <FilterType>(input: string | (string | null)[] | null | undefined): FilterType | undefined => {
  if (input == null) {
    return undefined
  }

  const filtersStr = input instanceof Array ? input[0] : input

  if (!filtersStr || !filtersStr.length) {
    return undefined
  }

  return compose<string, string[], [keyof FilterType, boolean | string[]][], FilterType | Record<string, unknown>, FilterType | undefined>(
    ifElse(isEmpty, always(undefined), identity),
    reduce((filters, [key, values]) => (isEmpty(values) ? filters : { ...filters, [key]: values }), {}),
    map<string, [keyof FilterType, boolean | string[]]>(entry => {
      const [key, valueString] = split(keyValueSeparator, entry) as [keyof FilterType, string]
      const values = split(valuesSeparator, valueString)

      if (values[0] === 'true' || values[0] === 'false') {
        /** boolean filters */
        return [key, values[0] === 'true']
      }

      return [key, values]
    }),
    split(entrySeparator)
  )(filtersStr)
}

export const FiltersParam = <FilterType>(): QueryParamConfig<Record<keyof FilterType, string[]>, FilterType | undefined> => ({
  encode: encodeFilters,
  decode: decodeFilters
})

export type AntSorterType<T> = Pick<SorterResult<T>, 'order' | 'columnKey'>

/**
 * Encodes antd table sorter
 *
 * @template SorterField
 * @param {AntSorterType<SorterField>} { columnKey, order } Only pick `order`, `columnKey` fields.
 * @returns {(string | undefined)} Example: `"sort"="-created"`
 */
export const encodeSorter = <SorterField>({ columnKey, order }: AntSorterType<SorterField>): string | undefined => {
  if (!order) {
    return undefined
  } else {
    return order === 'descend' ? `-${columnKey}` : `${columnKey}`
  }
}

/**
 * Decodes url to antd sorter type
 *
 * @template SorterField
 * @param {(string | string[])} sort
 * @returns {(AntSorterType<SorterField> | undefined)} Only pick `order`, `field` fields. Example: `{ "order": "descend", "field": "created" }`
 */
const decodeSorter = <SorterField extends string>(sort: string | (string | null)[] | null | undefined): AntSorterType<SorterField> | undefined => {
  /** not allow multiple fields sorting */
  if (!sort || sort instanceof Array) {
    return undefined
  } else {
    const order: SortOrder = sort.charAt(0) === '-' ? 'descend' : 'ascend'
    return {
      order,
      columnKey: replace('-', '', sort) as SorterField
    }
  }
}

export const SorterParam = <SorterField>(): QueryParamConfig<SorterResult<SorterField>, AntSorterType<SorterField> | undefined> => ({
  encode: encodeSorter,
  decode: decodeSorter
})

/** DateInterval is validated neither `undefined` nor `[undefined, moment]` nor `[undefined, undefined]` */
const isValidDateIntervalValue = value => value && !isEmpty(value) && all(d => !isNil(d), value)

/**
 * Encodes array of momnet if array of momnet is validate
 *
 * @param {([moment.Moment, moment.Moment] | moment.Moment)} dateInterval
 * @returns {(string | undefined)} Example: `"2019-10-07_2019-10-10"`
 */
const encodeDateInterval = (dateInterval: [moment.Moment, moment.Moment] | moment.Moment): string | undefined => {
  const _dateInterval = dateInterval instanceof Array ? dateInterval : [dateInterval.startOf('m'), dateInterval.endOf('m')]
  if (!isValidDateIntervalValue(dateInterval) && any(isEmptyOrNil, _dateInterval)) {
    return undefined
  }

  const getDateString = (moment: moment.Moment) => moment.format('YYYY-MM-DD')

  return compose(join(entrySeparator), map(getDateString))(_dateInterval)
}

/**
 * Decodes url to array of moment
 *
 * @param {(string | string[] | null | undefined)} input Example: `"2019-10-07_2019-10-10"`
 * @returns {([moment.Moment, moment.Moment] | undefined)} Example: `[moment('2019-10-07'),moment('2019-10-10')]`
 */
const decodeDateInterval = (input: string | (string | null)[] | null | undefined): [moment.Moment, moment.Moment] | undefined => {
  const dateIntervalString = input instanceof Array ? input[0] : input
  if (isNil(dateIntervalString)) {
    return undefined
  }

  return compose<string, [string, string], [moment.Moment, moment.Moment]>(
    ([startDateString, endDateString]) => [moment(startDateString).startOf('day'), moment(endDateString).endOf('day')],
    (input: string) => {
      const [startDateString, endDateString] = split(entrySeparator, input)
      return [startDateString, endDateString]
    }
  )(dateIntervalString)
}

export const getDateIntervalQueryString = (dateInterval?: [moment.Moment, moment.Moment]) => dateInterval && map(moment => `${moment.toISOString()}`, dateInterval)

export const DateIntervalParam: QueryParamConfig<[moment.Moment, moment.Moment], [moment.Moment, moment.Moment] | undefined> = {
  encode: encodeDateInterval,
  decode: decodeDateInterval
}

import { UploadOutlined } from '@ant-design/icons'
import { DatePicker, Form, Input, Radio, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import styled from 'styled-components'

import { MetaDescription } from '../../routes/case'
import { CaseCategory, Location } from '../../types/types'
import { getFileListFromEvent, takeLastFromFileList } from '../../utils/antd'
import DescriptionModal from '../DescriptionModal'
import GqlUpload from '../GqlUpload'
import RichTextEditor from '../RichTextEditor'
import WordsCal from '../WordsCal'
import DoctorSelect from './DoctorSelect'
import { ButtonWithIcon } from '../ButtonWithIcon'

export interface CaseFormFields {
  publishedDate: moment.Moment
  title: string
  pageTitle?: string
  description?: string
  alias?: string
  category: CaseCategory
  location?: Location
  doctor?: string
  thumbnailImage: UploadFile[]
  images?: UploadFile[]
  video?: string
  content?: string
}

const Question = styled.div`
  width: 19px;
  height: 19px;
  background-color: SteelBlue;
  border-radius: 50%;
  cursor: pointer;
  margin: 3px 8px 0 0;
`

interface Props {
  form: FormInstance<CaseFormFields>
  initialValues?: CaseFormFields
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const CaseForm: FC<Props> = props => {
  const { form, initialValues, setIsDirty } = props
  const { formatMessage } = useIntl()

  const initialDescription = initialValues?.description
  const initialLength = initialDescription ? initialDescription?.length : MetaDescription.length
  const [descriptionWordsCal, setDescriptionWordsCal] = useState(initialLength)
  const CheckDescriptionLength = () => {
    const descriptionLength = (document.getElementById('description') as HTMLInputElement).value.length
    setDescriptionWordsCal(descriptionLength === 0 ? MetaDescription.length : descriptionLength)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const onToggleModal = () => {
    setIsModalVisible(visible => !visible)
  }

  const handleValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={handleValuesChange} layout='vertical' scrollToFirstError>
      <Form.Item name='publishedDate' label='發布日期' rules={[{ required: true, message: '必填欄位' }]}>
        <DatePicker />
      </Form.Item>
      <Form.Item name='location' label='診所' rules={[{ required: true, message: '必填欄位' }]}>
        <Select>
          {Object.values(Location).map(value => (
            <Select.Option key={value} value={value}>
              {formatMessage({ id: `location.${value}` })}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='doctor' label='醫師'>
        <DoctorSelect doctor={initialValues?.doctor} />
      </Form.Item>
      <Form.Item name='pageTitle' label='頁面標題'>
        <Input placeholder='預設：見證標題' />
      </Form.Item>
      <Form.Item label='自訂 Meta 描述'>
        <Form.Item name='description' noStyle>
          <Input placeholder={`預設：${MetaDescription}`} id='description' onKeyUp={CheckDescriptionLength} />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Question onClick={onToggleModal}>&#10068;</Question>
          <WordsCal min={0} max={80} target={descriptionWordsCal} />
        </div>
        <DescriptionModal visible={isModalVisible} onToggleModal={onToggleModal} />
      </Form.Item>
      <Form.Item name='alias' label='自訂網址'>
        <Input placeholder='預設：隨機ID' />
      </Form.Item>
      <Form.Item name='title' label='標題' rules={[{ required: true, message: '必填欄位' }]}>
        <Input />
      </Form.Item>
      <Form.Item name='category' label='分類' rules={[{ required: true, message: '必填欄位' }]}>
        <Radio.Group>
          {Object.values(CaseCategory).map(category => (
            <Radio key={category} value={category}>
              {formatMessage({ id: `case.${category}` })}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name='thumbnailImage' label='縮圖' valuePropName='fileList' getValueFromEvent={takeLastFromFileList} rules={[{ required: true, message: '必填欄位' }]}>
        <GqlUpload listType='picture'>
          <ButtonWithIcon>
            <UploadOutlined /> 上傳縮圖
          </ButtonWithIcon>
        </GqlUpload>
      </Form.Item>
      <Form.Item name='images' label='圖片' valuePropName='fileList' getValueFromEvent={getFileListFromEvent}>
        <GqlUpload listType='picture' multiple>
          <ButtonWithIcon>
            <UploadOutlined /> 上傳圖片
          </ButtonWithIcon>
        </GqlUpload>
      </Form.Item>
      <Form.Item name='video' label='影片'>
        <Input />
      </Form.Item>
      <Form.Item name='content' label='內文'>
        <RichTextEditor />
      </Form.Item>
    </Form>
  )
}

export default CaseForm

import { Router } from '@reach/router'
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React, { FC } from 'react'

import bannerImage from '../../assets/static/banner/banner3.jpg'
import PageBanner from '../../components/Banner/PageBanner'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import NewsList from './List'
import NewsPage from './Page'

export const MetaTitle = '最新穎的矯正新知與關於日不落的最新衛教-日不落牙醫集團'

export const MetaDescription = '提供最新潮的牙齒矯正、陶瓷貼片新知與台北、新竹、台中、台南各診所最新衛教，給渴望想讓牙齒更亮麗的你/妳，看診前煩請填寫表單預約，由專人為您服務。'

const News: FC<PageRendererProps> = props => {
  const { location } = props
  const { formatMessage } = useIntl()

  return (
    <Layout location={location}>
      <Seo title={MetaTitle} metaDescription={MetaDescription} />
      <PageBanner imageUrl={bannerImage} h4title={formatMessage({ id: 'pageTitle.news' })} subtitle='News' />
      <Router>
        <NewsList path='/news' />
        <NewsPage path='/news/:newsId' />
      </Router>
      {/** 多語言的 client only route 需要定義另外的 route */}
      <Router>
        <NewsList path='/:locale/news' />
        <NewsPage path='/:locale/news/:newsId' />
      </Router>
    </Layout>
  )
}

export default News

import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, Row, Table, Tag, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnsType } from 'antd/lib/table'
import { Link, useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { map, values } from 'ramda'
import React, { FC } from 'react'
import { StringParam } from 'use-query-params'

import { DashboardNewsListDocs, DashboardNewsListQuery, DashboardNewsListQueryVariables, NewsCategory, NewsQuery } from '../../../types/types'
import { getAntdPagination } from '../../../utils/antd'
import useFormQuery from '../../../utils/hooks/useFormQuery'
import useTableQuery from '../../../utils/hooks/useTableQuery'

const newsListQuery = gql`
  query DashboardNewsList($query: NewsQuery, $page: Int, $limit: Int, $sort: String) {
    newsList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        category
        title
        publishedDate
      }
      page
      limit
      total
    }
  }
`

type NewsListFilterType = Pick<NewsQuery, 'category'>
type NewsListSortField = 'publishedDate'

interface NewsSearchFormFields {
  title?: string
}

interface NewsSearchFormProps {
  initialValues: NewsSearchFormFields
  handleSearch: (form: FormInstance<NewsSearchFormFields>) => void
  handleReset: () => void
}

const formInput = {
  title: StringParam
}

const NewsSearchForm: FC<NewsSearchFormProps> = props => {
  const { initialValues, handleSearch, handleReset } = props
  const [form] = Form.useForm<NewsSearchFormFields>()

  return (
    <Form form={form} initialValues={initialValues} layout='inline'>
      <Form.Item name='title' label='標題'>
        <Input onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Button onClick={handleReset}>重置</Button>
    </Form>
  )
}

const NewsList: FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()
  const { formQuery, handleFormChange, handleFormReset } = useFormQuery(formInput)
  const { tableQuery, handleTableChange, handleTableReset, cursor } = useTableQuery<NewsListFilterType, NewsListSortField>({ limit: 10, sort: '-publishedDate' })

  const initialValues = {
    title: formQuery.title || ''
  }

  const { data, loading } = useQuery<DashboardNewsListQuery, DashboardNewsListQueryVariables>(newsListQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: {
        ...initialValues,
        category: tableQuery.filters?.category
      },
      ...cursor
    }
  })

  const newsList = data?.newsList?.docs
  const pagination = data?.newsList ? getAntdPagination(data?.newsList) : false

  const columns: ColumnsType<DashboardNewsListDocs> = [
    {
      title: '#',
      width: '32px',
      render: (text, record, index) => <div>{index + 1}</div>
    },
    {
      title: '標題',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: '分類',
      key: 'category',
      width: '64px',
      filters: map(category => ({ text: formatMessage({ id: `news.${category}` }), value: category }), values(NewsCategory)),
      filteredValue: tableQuery.filters?.category ?? [],
      onFilter: (value, record) => record.category === value,
      render: (text, record) => <Tag>{formatMessage({ id: `news.${record.category}` })}</Tag>
    },
    {
      title: '發布日期',
      key: 'publishedDate',
      width: '128px',
      sortOrder: tableQuery.sort?.columnKey === 'publishedDate' ? tableQuery.sort.order : null,
      sorter: (a, b) => (moment(a.publishedDate).isBefore(moment(b.publishedDate)) ? -1 : 1),
      render: (text, record) => <>{moment(record.publishedDate).format('YYYY-MM-DD')}</>
    },
    {
      title: '操作',
      key: 'id',
      width: '64px',
      render: (text, record) => (
        <Link to={`/dashboard/news/${record.id}`}>
          <Button>編輯</Button>
        </Link>
      )
    }
  ]

  const handleSearch = (form: FormInstance<NewsSearchFormFields>) => {
    handleFormChange(form.getFieldsValue())
  }
  const handleReset = () => {
    handleFormReset()
    handleTableReset()
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Typography.Title>衛教列表</Typography.Title>
        <NewsSearchForm initialValues={initialValues} handleSearch={handleSearch} handleReset={handleReset} />
      </Row>
      <Table rowKey='id' loading={loading} dataSource={newsList} columns={columns} pagination={pagination} onChange={handleTableChange} />
    </>
  )
}

export default NewsList

import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, Row, Table, Tag, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnsType } from 'antd/lib/table'
import { Link, useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { map, values } from 'ramda'
import React, { FC } from 'react'
import { StringParam } from 'use-query-params'

import { CaseCategory, CasesQuery, DashboardCaseListDocs, DashboardCaseListQuery, DashboardCaseListQueryVariables, Location } from '../../../types/types'
import { getAntdPagination } from '../../../utils/antd'
import useFormQuery from '../../../utils/hooks/useFormQuery'
import useTableQuery from '../../../utils/hooks/useTableQuery'
import DoctorWithLocation from './DoctorWithLocation'

const caseListQuery = gql`
  query DashboardCaseList($query: CasesQuery, $page: Int, $limit: Int, $sort: String) {
    cases(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        category
        location
        title
        name
        doctor
        publishedDate
        thumbnailImage
      }
      page
      limit
      total
    }
  }
`

type CaseListFilterType = Pick<CasesQuery, 'category'>
type CaseListSortField = 'publishedDate'

interface CaseSearchFormFields {
  name?: string
  title?: string
}

interface CaseSearchFormProps {
  initialValues: CaseSearchFormFields
  handleSearch: (form: FormInstance<CaseSearchFormFields>) => void
  handleReset: () => void
}

const formInput = {
  name: StringParam,
  title: StringParam
}

const CaseSearchForm: FC<CaseSearchFormProps> = props => {
  const { initialValues, handleSearch, handleReset } = props
  const [form] = Form.useForm<CaseSearchFormFields>()

  return (
    <Form form={form} initialValues={initialValues} layout='inline'>
      <Form.Item name='title' label='標題'>
        <Input onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Button onClick={handleReset}>重置</Button>
    </Form>
  )
}

const CaseList: FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()
  const { formQuery, handleFormChange, handleFormReset } = useFormQuery(formInput)
  const { tableQuery, handleTableChange, cursor } = useTableQuery<CaseListFilterType, CaseListSortField>({ limit: 10, sort: '-publishedDate' })

  const initialValues = {
    name: formQuery.name || '',
    title: formQuery.title || ''
  }

  const { data, loading } = useQuery<DashboardCaseListQuery, DashboardCaseListQueryVariables>(caseListQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: {
        ...initialValues,
        category: tableQuery.filters?.category
      },
      ...cursor
    }
  })

  const cases = data?.cases?.docs
  const pagination = data?.cases ? getAntdPagination(data?.cases) : false

  const columns: ColumnsType<DashboardCaseListDocs> = [
    {
      title: '#',
      width: '32px',
      render: (text, record, index) => <div>{index + 1}</div>
    },
    {
      title: '縮圖',
      key: 'thumbnailImage',
      width: '128px',
      render: (text, record) => <img width='128px' src={record.thumbnailImage} />
    },
    {
      title: '醫師',
      key: 'doctor',
      width: '128px',
      render: (text, record) => <DoctorWithLocation value={record.doctor} location={record.location ? record.location : Location.Taipei} />
    },
    {
      title: '標題',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: '分類',
      key: 'category',
      width: '64px',
      filters: map(category => ({ text: formatMessage({ id: `case.${category}` }), value: category }), values(CaseCategory)),
      filteredValue: tableQuery.filters?.category ?? [],
      onFilter: (value, record) => record.category === value,
      render: (text, record) => <Tag>{formatMessage({ id: `case.${record.category}` })}</Tag>
    },
    {
      title: '發布日期',
      key: 'publishedDate',
      width: '128px',
      sortOrder: tableQuery.sort?.columnKey === 'publishedDate' ? tableQuery.sort.order : null,
      sorter: (a, b) => (moment(a.publishedDate).isBefore(moment(b.publishedDate)) ? -1 : 1),
      render: (text, record) => <>{moment(record.publishedDate).format('YYYY-MM-DD')}</>
    },
    {
      title: '操作',
      key: 'id',
      width: '64px',
      render: (text, record) => (
        <Link to={`/dashboard/cases/${record.id}`}>
          <Button>編輯</Button>
        </Link>
      )
    }
  ]

  const handleSearch = (form: FormInstance<CaseSearchFormFields>) => {
    handleFormChange(form.getFieldsValue())
  }
  const handleReset = () => {
    handleFormReset()
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Typography.Title>見證列表</Typography.Title>
        <CaseSearchForm initialValues={initialValues} handleSearch={handleSearch} handleReset={handleReset} />
      </Row>
      <Table rowKey='id' loading={loading} dataSource={cases} columns={columns} pagination={pagination} onChange={handleTableChange} />
    </>
  )
}

export default CaseList

import { navigate } from "gatsby-plugin-intl";
import React from "react";

interface LinkWithConfirmProps {
  to: string
  isDirty: boolean
  children: React.ReactNode
}

const LinkWithConfirm = ({ to, isDirty, children }:LinkWithConfirmProps) => {
  const handleRouteChange = () => {
    if (isDirty) {
      const confirmed = window.confirm(
        "系統可能不會儲存你所做的變更。 要離開頁面嗎?"
      );
      if (confirmed) {
        navigate(to);
      }
    } else {
      navigate(to);
    }
  };

  return <div onClick={handleRouteChange}>{children}</div>;
};

export default LinkWithConfirm;

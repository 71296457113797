import { gql, useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Popconfirm, Skeleton, Space, Typography, message } from 'antd'
import { Link, navigate } from 'gatsby-plugin-intl'
import { isNil } from 'ramda'
import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'

import DoctorForm, { DoctorFormFields } from '../../../components/forms/DoctorForm'
import {
  DashboardDoctorQuery,
  DashboardDoctorQueryVariables,
  RemoveDoctorMutation,
  RemoveDoctorMutationVariables,
  UpdateDoctorMutation,
  UpdateDoctorMutationVariables
} from '../../../types/types'
import { getFileObjectFromUrl } from '../../../utils/antd'
import { getPayloadFromFieldsValue } from './utils'
import LinkWithConfirm from '../../../components/LinkWithConfirm'

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const doctorQuery = gql`
  query DashboardDoctor($id: ID!) {
    doctors(id: $id) {
      id
      name
      image
      location
      title
      strength
      experience
      elite
      commend
      order
    }
  }
`

const updateDoctor = gql`
  mutation UpdateDoctor($id: ID!, $payload: UpdateDoctorsInput!) {
    updateDoctors(id: $id, payload: $payload) {
      id
    }
  }
`

const removeDoctor = gql`
  mutation RemoveDoctor($id: ID!) {
    removeDoctors(id: $id) {
      id
    }
  }
`

interface DoctorPageProps extends RouteComponentProps<{ id: string }> {
  isDirty: boolean
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const DoctorPage: FC<DoctorPageProps> = props => {
  const { id: doctorId, isDirty, setIsDirty } = props

  if (isNil(doctorId)) {
    message.error('無法取得 ID')
    return (
      <Link to='/dashboard/doctors'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const { data, loading, refetch } = useQuery<DashboardDoctorQuery, DashboardDoctorQueryVariables>(doctorQuery, {
    variables: {
      id: doctorId
    }
  })
  const [update] = useMutation<UpdateDoctorMutation, UpdateDoctorMutationVariables>(updateDoctor)
  const [remove] = useMutation<RemoveDoctorMutation, RemoveDoctorMutationVariables>(removeDoctor)
  const [form] = Form.useForm<DoctorFormFields>()
  const doctorData = data?.doctors

  if (loading) {
    return <Skeleton active />
  }
  if (isNil(doctorData)) {
    message.error('查無資料，請返回列表頁')
    return (
      <Link to='/dashboard/doctors'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const initialValues = {
    name: doctorData.name,
    image: [getFileObjectFromUrl(doctorData.image || '', { uid: doctorId, name: doctorData.title })],
    location: doctorData.location,
    title: doctorData.title,
    strength: doctorData.strength,
    experience: doctorData.experience,
    elite: doctorData.elite,
    commend: doctorData.commend,
    order: doctorData.order
  }

  const handleUpdate = async () => {
    try {
      const fieldsValue = await form.validateFields()
      await update({
        variables: {
          id: doctorId,
          payload: getPayloadFromFieldsValue(fieldsValue as DoctorFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.updateDoctors) {
            message.info(`已更新 ${fieldsValue.name} 醫師 \u{1F9DA}`)
            refetch()
            navigate('/dashboard/doctors')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  const handleRemove = async () => {
    try {
      await remove({
        variables: { id: doctorId },
        update: async (cache, { data }) => {
          if (data?.removeDoctors) {
            message.info(`已刪除醫師`)
            navigate('/dashboard/doctors')
          }
        }
      })
    } catch (e) {
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>編輯醫師</Typography.Title>
      {loading || isNil(doctorData) ? (
        <Skeleton active />
      ) : (
        <>
          <DoctorForm form={form} initialValues={initialValues} setIsDirty={setIsDirty} />
          <ButtonRow>
            <Space>
              <Button type='primary' onClick={handleUpdate}>
                更新
              </Button>
              <Button>
                <LinkWithConfirm to='/dashboard/doctors' isDirty={isDirty}>
                  回列表
                </LinkWithConfirm>
              </Button>
            </Space>
            <Popconfirm title='確定刪除醫師？' onConfirm={handleRemove}>
              <Button type='primary' danger>
                刪除
              </Button>
            </Popconfirm>
          </ButtonRow>
        </>
      )}
    </>
  )
}

export default DoctorPage

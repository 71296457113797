import { gql, useQuery } from '@apollo/client'
import { Select as AntdSelect } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import styled from 'styled-components'

import DoctorImgMissing from '../../assets/static/doctors/doctorImgMissing.png'
import { DashboardDoctorSelectListQuery, DashboardDoctorSelectListQueryVariables } from '../../types/types'

const Option = AntdSelect.Option
const Select = AntdSelect

const doctorSelectListQuery = gql`
  query DashboardDoctorSelectList($query: DoctorsQuery, $page: Int, $limit: Int, $sort: String) {
    doctorsList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        created
        location
        title
        name
        elite
        image
      }
      page
      limit
      total
    }
  }
`

interface DoctorSelectProps {
  onChange?: SelectProps<any>['onChange']
  doctor?: string
}

interface DoctorItemProps {
  img: string
}
const DoctorItem = styled.div<DoctorItemProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
  :before {
    content: '';
    display: block;
    height: 32px;
    width: 32px;
    background-image: url(${props => props.img});
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    margin-right: 8px;
  }
`
const NoDoctor = styled.div`
  font-size: 14px;
`

const DoctorSelect = (props: DoctorSelectProps) => {
  const { onChange, doctor } = props

  const { data } = useQuery<DashboardDoctorSelectListQuery, DashboardDoctorSelectListQueryVariables>(doctorSelectListQuery, {
    variables: { limit: 200, page: 1 }
  })

  const doctorList = data?.doctorsList?.docs

  return (
    <Select size='large' defaultValue={doctor || 'none'} onChange={onChange}>
      <Option value='none'>
        <NoDoctor>未指定</NoDoctor>
      </Option>
      {doctorList?.map(x => (
        <Option key={x.id} value={x.name}>
          <DoctorItem img={x.image || DoctorImgMissing}>{x.name}</DoctorItem>
        </Option>
      ))}
    </Select>
  )
}

export default DoctorSelect

import React from 'react'
import styled from 'styled-components'

import RewardImg from '../../../assets/images/reward.svg'

const Wrapper = styled.div`
  position: relative;
`
interface RewardProps {
  show?: boolean
}
const Reward = styled.img<RewardProps>`
  display: ${props => (props.show ? 'block' : 'none')};
  width: 30px;
  position: absolute;
  top: -4px;
  left: -9px;
`
interface ImgProps {
  imageUrl: string
}
const Img = styled.div<ImgProps>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
`
interface DoctorImgProps {
  value: any
  isElite?: boolean
}
const DoctorImg = (props: DoctorImgProps) => {
  const { value, isElite } = props

  return (
    <Wrapper>
      <Reward src={RewardImg} show={isElite} />
      <Img imageUrl={value} />
    </Wrapper>
  )
}

export default DoctorImg

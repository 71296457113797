import moment from 'moment'

import { NewsFormFields } from '../../../components/forms/NewsForm'

export const getPayloadFromFieldsValue = (fieldsValue: NewsFormFields) => ({
  title: fieldsValue.title,
  pageTitle: fieldsValue.pageTitle,
  description: fieldsValue.description,
  alias: fieldsValue.alias,
  content: fieldsValue.content,
  video: fieldsValue.video,
  category: fieldsValue.category,
  images: fieldsValue.images?.map(image => image?.response?.url),
  thumbnailImage: fieldsValue.thumbnailImage?.[0]?.response?.url,
  thumbnailAlt: fieldsValue.thumbnailAlt,
  publishedDate: moment(fieldsValue.publishedDate).toDate()
})

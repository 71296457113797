import { UploadOutlined } from '@ant-design/icons'

import { Form, Input, InputNumber, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { ButtonWithIcon } from '../ButtonWithIcon'

import { takeLastFromFileList } from '../../utils/antd'
import GqlUpload from '../GqlUpload'

export interface BannerFormFields {
  image: UploadFile[]
  link?: string
  description?: string
  active?: boolean
  order?: number
}

interface Props {
  form: FormInstance<BannerFormFields>
  initialValues?: BannerFormFields
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const BannerForm: FC<Props> = props => {
  const { form, initialValues, setIsDirty } = props

  const handleValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={handleValuesChange} layout='vertical' scrollToFirstError>
      <Form.Item
        name='image'
        valuePropName='fileList'
        getValueFromEvent={takeLastFromFileList}
        rules={[{ required: true, message: '必填欄位' }]}
        label='橫幅圖片 【尺寸：1280 × 464 像素】'
      >
        <GqlUpload listType='picture'>
          <ButtonWithIcon>
            <UploadOutlined /> 上傳橫幅圖片
          </ButtonWithIcon>
        </GqlUpload>
      </Form.Item>
      <Form.Item name='description' label='圖片說明'>
        <Input />
      </Form.Item>
      <Form.Item name='link' label='連結頁面'>
        <Input />
      </Form.Item>
      <Form.Item name='active' label='啟用'>
        <Switch defaultChecked={initialValues?.active} />
      </Form.Item>
      <Form.Item name='order' label='排列順序'>
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

export default BannerForm

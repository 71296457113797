import { Editable, EditableInput as _EditableInput, EditablePreview as _EditablePreview } from '@chakra-ui/core'
import { isEmpty, isNil } from 'ramda'
import React, { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'

const EditableInput = styled(_EditableInput)``
const EditablePreview = styled(_EditablePreview)`
  && {
    text-align: center;
    position: relative;
    :hover {
      cursor: pointer;
      color: SteelBlue;
      :before {
        content: '點選編輯';
        position: absolute;
        padding: 2px 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        color: #000000;
        background-color: rgb(255, 255, 255);
        bottom: -35px;
        right: 0;
        filter: opacity(60%);
      }
    }
  }
`

interface EditableFiledProps {
  defaultPlaceholder?: ReactNode
  value: any
  handleSubmit: (formValues: any) => void
}
const EditableFiled: FC<EditableFiledProps> = props => {
  const { value, handleSubmit } = props
  const isEmptyValue = isNil(value) || isEmpty(value)
  const defaultValue = isEmptyValue ? '-' : value
  const [fieldValue, setFieldValue] = useState(defaultValue)
  const onSubmit = () => {
    handleSubmit(fieldValue)
  }
  return (
    <Editable textAlign='center' value={fieldValue} fontSize='14px' onSubmit={onSubmit} onChange={setFieldValue} w='100px'>
      <EditablePreview w='100%' />
      <EditableInput w='100%' border='1px solid LightSteelBlue' borderRadius='5px' />
    </Editable>
  )
}
export default EditableFiled

import { UploadOutlined } from '@ant-design/icons'
import { Form, Input, InputNumber, Select, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import { useIntl } from 'gatsby-plugin-intl'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import { Location } from '../../types/types'
import { takeLastFromFileList } from '../../utils/antd'
import GqlUpload from '../GqlUpload'
import { ButtonWithIcon } from '../ButtonWithIcon'

export interface DoctorFormFields {
  name: string
  image?: UploadFile[]
  location: Location[]
  title?: string
  strength?: string
  experience?: string
  elite?: boolean
  commend?: string
  order?: number
}

const { TextArea } = Input

interface Props {
  form: FormInstance<DoctorFormFields>
  initialValues?: DoctorFormFields
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const DoctorForm: FC<Props> = props => {
  const { form, initialValues, setIsDirty } = props
  const { formatMessage } = useIntl()

  const [commendDisable, setCommendDisable] = useState(!initialValues?.elite)
  const handleCommend = (value: boolean) => {
    setCommendDisable(!value)
  }

  const handleValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={handleValuesChange} layout='vertical' scrollToFirstError>
      <Form.Item name='name' label='醫師' rules={[{ required: true, message: '必填欄位' }]}>
        <Input />
      </Form.Item>
      <Form.Item name='image' label='圖片' valuePropName='fileList' getValueFromEvent={takeLastFromFileList}>
        <GqlUpload listType='picture'>
          <ButtonWithIcon>
            <UploadOutlined /> 上傳縮圖
          </ButtonWithIcon>
        </GqlUpload>
      </Form.Item>
      <Form.Item name='location' label='服務診所'>
        <Select mode='multiple'>
          {Object.values(Location).map(value => (
            <Select.Option key={value} value={value}>
              {formatMessage({ id: `location.${value}` })}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='title' label='專科領域'>
        <Input />
      </Form.Item>
      <Form.Item name='strength' label='主治專長'>
        <Input />
      </Form.Item>
      <Form.Item name='experience' label='學經歷'>
        <TextArea rows={6} />
      </Form.Item>
      <Form.Item name='elite' label='菁英團隊'>
        <Switch onClick={handleCommend} defaultChecked={initialValues?.elite} />
      </Form.Item>
      <Form.Item name='commend' label='患者好評'>
        <TextArea rows={2} disabled={commendDisable} />
      </Form.Item>
      <Form.Item name='order' label='排列順序'>
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

export default DoctorForm

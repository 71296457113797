import { Modal } from 'antd'
import React from 'react'
import styled from 'styled-components'

import metaDescription from '../assets/static/metaDescription.png'

const ContentWrapper = styled.div`
  text-align: center;
  color: #444444;

  .notice {
    margin-bottom: 8px;
  }
`

const Content = styled.div`
  border: 0.5rem ridge #d6d6d2;
  border-radius: 12px;
  font: bold 1.3rem sans-serif;
  margin: 1.5rem;
  padding: 0.5rem;
  outline-offset: 0.5rem;
`

const Image = styled.div<{ imageUrl: string }>`
  margin: 10px auto;
  width: 630px;
  height: 135px;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.imageUrl});
`

interface QuestionModalProps {
  onToggleModal: () => void
  visible: boolean
}

const DescriptionModal = (props: QuestionModalProps) => {
  const { onToggleModal, visible } = props

  return (
    <Modal title={null} footer={null} visible={visible} onCancel={onToggleModal} width={711}>
      <ContentWrapper>
        <Content>meta description位置</Content>
        <div className='notice'>&#128276; &nbsp;過多的字數將以省略符號取代，故儘量在有限的字數範圍內新增關鍵字</div>
        <div className='notice'>
          &#128276; &nbsp;為因應不同載體（電腦、手機⋯⋯等）的螢幕大小，擷取長度可能不同，
          <br />
          因此關鍵字置入點越前面越好
        </div>
      </ContentWrapper>
      <Image imageUrl={metaDescription} />
    </Modal>
  )
}

export default DescriptionModal

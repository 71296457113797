import { gql, useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Space, Typography, message } from 'antd'
import { navigate } from 'gatsby-plugin-intl'
import React, { Dispatch, FC, SetStateAction } from 'react'

import NewsForm, { NewsFormFields } from '../../../components/forms/NewsForm'
import { CreateNewsMutation, CreateNewsMutationVariables } from '../../../types/types'
import { getPayloadFromFieldsValue } from './utils'
import LinkWithConfirm from '../../../components/LinkWithConfirm'

const createNews = gql`
  mutation CreateNews($payload: CreateNewsInput!) {
    createNews(payload: $payload) {
      id
    }
  }
`

interface NewsCreateProps extends RouteComponentProps {
  isDirty: boolean
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const NewsCreate: FC<NewsCreateProps> = props => {
  const { isDirty, setIsDirty } = props
  const [form] = Form.useForm<NewsFormFields>()
  const [create] = useMutation<CreateNewsMutation, CreateNewsMutationVariables>(createNews)

  const handleCreate = async () => {
    try {
      const fieldsValue = await form.validateFields()
      await create({
        variables: {
          payload: getPayloadFromFieldsValue(fieldsValue as NewsFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.createNews) {
            message.success('已新增衛教')
            navigate('/dashboard/news')
          } else {
            message.error('Oop！衛教網址重複 \u{1F925}')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>新增衛教</Typography.Title>
      <NewsForm form={form} setIsDirty={setIsDirty} />
      <Space>
        <Button type='primary' onClick={handleCreate}>
          新增
        </Button>
        <Button>
          <LinkWithConfirm to='/dashboard/news' isDirty={isDirty}>
            回列表
          </LinkWithConfirm>
        </Button>
      </Space>
    </>
  )
}

export default NewsCreate

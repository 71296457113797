import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, Row, Table, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnsType } from 'antd/lib/table'
import { Link, useIntl } from 'gatsby-plugin-intl'
import { map, values } from 'ramda'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StringParam } from 'use-query-params'

import RewardImg from '../../../assets/images/reward.svg'
import DoctorImgMissing from '../../../assets/static/doctors/doctorImgMissing.png'
import { DashboardDoctorListDocs, DashboardDoctorListQuery, DashboardDoctorListQueryVariables, DoctorsQuery, Location } from '../../../types/types'
import { getAntdPagination } from '../../../utils/antd'
import useFormQuery from '../../../utils/hooks/useFormQuery'
import useTableQuery from '../../../utils/hooks/useTableQuery'
import LocationLabel from '../comment/LocationLabel'
import DoctorImg from './DoctorImg'

const doctorListQuery = gql`
  query DashboardDoctorList($query: DoctorsQuery, $page: Int, $limit: Int, $sort: String) {
    doctorsList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        created
        location
        title
        name
        elite
        image
        order
      }
      page
      limit
      sort
      total
    }
  }
`

const RewardIntro = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const Img = styled.img`
  width: 24px;
  margin: 0 4px;
`

type DoctorListFilterType = Pick<DoctorsQuery, 'location'>
type DoctorListSortField = 'order'

interface DoctorSearchFormFields {
  name?: string
  location?: string
}

interface DoctorSearchFormProps {
  initialValues: DoctorSearchFormFields
  handleSearch: (form: FormInstance<DoctorSearchFormFields>) => void
  handleReset: () => void
}

const formInput = {
  name: StringParam,
  location: StringParam
}

const DoctorSearchForm: FC<DoctorSearchFormProps> = props => {
  const { initialValues, handleSearch, handleReset } = props
  const [form] = Form.useForm<DoctorSearchFormFields>()

  return (
    <Form form={form} initialValues={initialValues} layout='inline'>
      <Form.Item name='name' label='醫師'>
        <Input onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Button onClick={handleReset}>重置</Button>
    </Form>
  )
}

const DoctorList: FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl()
  const { formQuery, handleFormChange, handleFormReset } = useFormQuery(formInput)
  const { tableQuery, handleTableChange, cursor } = useTableQuery<DoctorListFilterType, DoctorListSortField>({ limit: 10, sort: 'order' })

  const initialValues = {
    name: formQuery.name || ''
  }

  const { data, loading } = useQuery<DashboardDoctorListQuery, DashboardDoctorListQueryVariables>(doctorListQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: {
        ...initialValues,
        location: tableQuery.filters?.location
      },
      ...cursor,
      sort: 'order'
    }
  })

  const doctors = data?.doctorsList?.docs

  const pagination = data?.doctorsList ? getAntdPagination(data?.doctorsList) : false

  const columns: ColumnsType<DashboardDoctorListDocs> = [
    {
      title: '#',
      width: '32px',
      render: (text, record, index) => <div>{index + 1}</div>
    },
    {
      title: '圖片',
      key: 'image',
      width: '128px',
      render: (text, record) => {
        return <DoctorImg value={record.image || DoctorImgMissing} isElite={record.elite} />
      }
    },
    {
      title: '醫師',
      key: 'name',
      width: '128px',
      dataIndex: 'name'
    },
    {
      title: '排列順序',
      key: 'order',
      width: '128px',
      dataIndex: 'order',
      align: 'center',
      sortOrder: tableQuery.sort?.columnKey === 'order' ? tableQuery.sort.order : null,
      sorter: (a, b) => a.order - b.order
    },
    {
      title: '專科領域',
      key: 'title',
      width: '150px',
      dataIndex: 'title'
    },
    {
      title: '服務診所',
      key: 'location',
      filters: map(location => ({ text: formatMessage({ id: `location.${location}` }), value: location }), values(Location)),
      filteredValue: tableQuery.filters?.location ?? [],
      render: (text, record) => {
        return (
          <div>
            {record.location?.map(value => (
              <LocationLabel value={value} key={value} />
            ))}
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'id',
      width: '150px',
      align: 'center',
      render: (text, record) => (
        <Link to={`/dashboard/doctors/${record.id}`}>
          <Button>編輯</Button>
        </Link>
      )
    }
  ]

  const handleSearch = (form: FormInstance<DoctorSearchFormFields>) => {
    handleFormChange(form.getFieldsValue())
  }
  const handleReset = () => {
    handleFormReset()
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Typography.Title>醫師列表</Typography.Title>
        <DoctorSearchForm initialValues={initialValues} handleSearch={handleSearch} handleReset={handleReset} />
      </Row>
      <RewardIntro>
        註：
        <Img src={RewardImg} />
        為菁英團隊醫師
      </RewardIntro>
      <Table rowKey='id' loading={loading} dataSource={doctors} columns={columns} pagination={pagination} onChange={handleTableChange} />
    </>
  )
}

export default DoctorList

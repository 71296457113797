import React from 'react'
import styled from 'styled-components'

import { Location } from '../../../types/types'
import LocationLabel from '../comment/LocationLabel'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface Props {
  value?: string
  location: Location
}
const DoctorWithLocation = (props: Props) => {
  const { value, location } = props

  return (
    <Wrapper>
      <LocationLabel value={location} key={location} />
      {!value || value === 'none' ? '無指定醫師' : value}
    </Wrapper>
  )
}

export default DoctorWithLocation

import { gql, useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Space, Typography, message } from 'antd'
import { navigate } from 'gatsby-plugin-intl'
import React, { Dispatch, FC, SetStateAction } from 'react'

import CaseForm, { CaseFormFields } from '../../../components/forms/CaseForm'
import { CreateCaseMutation, CreateCaseMutationVariables } from '../../../types/types'
import { getPayloadFromFieldsValue } from './utils'
import LinkWithConfirm from '../../../components/LinkWithConfirm'

const createCase = gql`
  mutation CreateCase($payload: CreateCaseInput!) {
    createCase(payload: $payload) {
      id
    }
  }
`

interface CaseCreateProps extends RouteComponentProps {
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const CaseCreate: FC<CaseCreateProps> = (props) => {
  const { isDirty, setIsDirty } = props
  const [form] = Form.useForm<CaseFormFields>()
  const [create] = useMutation<CreateCaseMutation, CreateCaseMutationVariables>(createCase)

  const handleCreate = async () => {
    try {
      const fieldsValue = await form.validateFields()

      await create({
        variables: {
          payload: getPayloadFromFieldsValue(fieldsValue as CaseFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.createCase) {
            message.success('已新增見證')
            navigate('/dashboard/cases')
          } else {
            message.error('Oop！網址重複 \u{1F635}')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>新增見證</Typography.Title>
      <CaseForm form={form} setIsDirty={setIsDirty} />
      <Space>
        <Button type='primary' onClick={handleCreate}>
          新增
        </Button>
        <Button>
          <LinkWithConfirm to='/dashboard/cases' isDirty={isDirty}>
            回列表
          </LinkWithConfirm>
        </Button>
      </Space>
    </>
  )
}

export default CaseCreate

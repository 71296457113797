import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, Row, Table, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'gatsby-plugin-intl'
import React, { FC } from 'react'
import { StringParam } from 'use-query-params'

import { BannersQuery, DashboardBannerListDocs, DashboardBannerListQuery, DashboardBannerListQueryVariables } from '../../../types/types'
import { getAntdPagination } from '../../../utils/antd'
import useFormQuery from '../../../utils/hooks/useFormQuery'
import useTableQuery from '../../../utils/hooks/useTableQuery'

const bannerListQuery = gql`
  query DashboardBannerList($query: BannersQuery, $page: Int, $limit: Int, $sort: String) {
    bannersList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        image
        link
        description
        active
        order
      }
      page
      limit
      sort
      total
    }
  }
`

type BannerListFilterType = Pick<BannersQuery, 'description'>
type BannerListSortField = 'order'

interface BannerSearchFormFields {
  description?: string
}

interface BannerSearchFormProps {
  initialValues: BannerSearchFormFields
  handleSearch: (form: FormInstance<BannerSearchFormFields>) => void
  handleReset: () => void
}

const formInput = {
  description: StringParam
}

const BannerSearchForm: FC<BannerSearchFormProps> = props => {
  const { initialValues, handleSearch, handleReset } = props
  const [form] = Form.useForm<BannerSearchFormFields>()

  return (
    <Form form={form} initialValues={initialValues} layout='inline'>
      <Form.Item name='description' label='圖片說明'>
        <Input onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Button onClick={handleReset}>重置</Button>
    </Form>
  )
}

const BannerList: FC<RouteComponentProps> = () => {
  const { formQuery, handleFormChange, handleFormReset } = useFormQuery(formInput)
  const { tableQuery, handleTableChange, cursor } = useTableQuery<BannerListFilterType, BannerListSortField>({ limit: 10, sort: 'order' })

  const initialValues = {
    description: formQuery.description || ''
  }

  const { data, loading } = useQuery<DashboardBannerListQuery, DashboardBannerListQueryVariables>(bannerListQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: {
        ...initialValues
      },
      ...cursor,
      sort: 'order'
    }
  })

  const banners = data?.bannersList?.docs

  const pagination = data?.bannersList ? getAntdPagination(data?.bannersList) : false

  const columns: ColumnsType<DashboardBannerListDocs> = [
    {
      title: '#',
      width: '32px',
      render: (text, record, index) => <div>{index + 1}</div>
    },
    {
      title: '橫幅圖片',
      key: 'image',
      width: '128px',
      render: (text, record) => <img width='128px' src={record.image} />
    },
    {
      title: '排列順序',
      key: 'order',
      width: '128px',
      dataIndex: 'order',
      align: 'center',
      sortOrder: tableQuery.sort?.columnKey === 'order' ? tableQuery.sort.order : null,
      sorter: (a, b) => a.order - b.order
    },
    {
      title: '啟用',
      width: '100px',
      key: 'active',
      dataIndex: 'active',
      align: 'center',
      render: (text, record) => (
        <span>
          {record.active ? <CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: '20px' }} /> : <CloseCircleTwoTone twoToneColor='#eb2f96' style={{ fontSize: '20px' }} />}
        </span>
      )
    },
    {
      title: '圖片說明',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: '操作',
      key: 'id',
      width: '150px',
      align: 'center',
      render: (text, record) => (
        <Link to={`/dashboard/banners/${record.id}`}>
          <Button>編輯</Button>
        </Link>
      )
    }
  ]

  const handleSearch = (form: FormInstance<BannerSearchFormFields>) => {
    handleFormChange(form.getFieldsValue())
  }
  const handleReset = () => {
    handleFormReset()
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Typography.Title>橫幅列表</Typography.Title>
        <BannerSearchForm initialValues={initialValues} handleSearch={handleSearch} handleReset={handleReset} />
      </Row>
      <Table rowKey='id' loading={loading} dataSource={banners} columns={columns} pagination={pagination} onChange={handleTableChange} />
    </>
  )
}

export default BannerList

import { gql, useMutation } from '@apollo/client'
import { Upload, message } from 'antd'
import { UploadProps } from 'antd/lib/upload'
import React, { FC } from 'react'

import { UploadFileMutation, UploadFileMutationVariables } from '../types/types'

const uploadFile = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`

type Props = UploadProps & {
  setPicturePath?: (x) => void
}

const GqlUpload: FC<Props> = props => {
  const { setPicturePath } = props
  const [upload] = useMutation<UploadFileMutation, UploadFileMutationVariables>(uploadFile)

  return (
    <Upload
      customRequest={option => {
        const { onError, onSuccess, file } = option

        try {
          upload({
            variables: {
              file
            },
            update: async (_cache, { data }) => {
              if (data) {
                onSuccess({ url: data.uploadFile }, file)
                setPicturePath?.(data.uploadFile)
              }
            }
          })
        } catch (error) {
          console.error('error', error)
          if (error.message) {
            message.error(error.message)
          }
          onError(error)
        }
      }}
      {...props}
    />
  )
}

export default GqlUpload

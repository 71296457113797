import { gql, useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Popconfirm, Skeleton, Space, Typography, message } from 'antd'
import { Link, navigate } from 'gatsby-plugin-intl'
import moment from 'moment'
import { isNil } from 'ramda'
import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'

import CaseForm, { CaseFormFields } from '../../../components/forms/CaseForm'
import {
  DashboardCaseQuery,
  DashboardCaseQueryVariables,
  RemoveCaseMutation,
  RemoveCaseMutationVariables,
  UpdateCaseMutation,
  UpdateCaseMutationVariables
} from '../../../types/types'
import { getFileObjectFromUrl } from '../../../utils/antd'
import { getPayloadFromFieldsValue } from './utils'
import LinkWithConfirm from '../../../components/LinkWithConfirm'

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const caseQuery = gql`
  query DashboardCase($id: ID!) {
    case(id: $id) {
      id
      category
      location
      title
      pageTitle
      description
      alias
      name
      doctor
      content
      publishedDate
      images
      thumbnailImage
      video
    }
  }
`

const updateCase = gql`
  mutation UpdateCase($id: ID!, $payload: UpdateCaseInput!) {
    updateCase(id: $id, payload: $payload) {
      id
    }
  }
`

const removeCase = gql`
  mutation RemoveCase($id: ID!) {
    removeCase(id: $id) {
      id
    }
  }
`

interface CasePageProps extends RouteComponentProps<{ id: string }> {
  isDirty: boolean
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

const CasePage: FC<CasePageProps> = props => {
  const { id: caseId, isDirty, setIsDirty } = props

  if (isNil(caseId)) {
    message.error('無法取得 ID')
    return (
      <Link to='/dashboard/cases'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const { data, loading, refetch } = useQuery<DashboardCaseQuery, DashboardCaseQueryVariables>(caseQuery, {
    variables: {
      id: caseId
    }
  })
  const [update] = useMutation<UpdateCaseMutation, UpdateCaseMutationVariables>(updateCase)
  const [remove] = useMutation<RemoveCaseMutation, RemoveCaseMutationVariables>(removeCase)
  const [form] = Form.useForm<CaseFormFields>()
  const caseData = data?.case

  if (loading) {
    return <Skeleton active />
  }
  if (isNil(caseData)) {
    message.error('查無資料，請返回列表頁')
    return (
      <Link to='/dashboard/cases'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const initialValues = {
    publishedDate: moment(caseData.publishedDate),
    title: caseData.title,
    category: caseData.category,
    location: caseData.location,
    doctor: caseData.doctor,
    pageTitle: caseData.pageTitle,
    description: caseData.description,
    alias: caseData.alias,
    content: caseData.content,
    video: caseData.video,
    images: caseData.images?.map((image, index) => getFileObjectFromUrl(image, { uid: `image-${index}`, name: `圖片${index + 1}` })),
    thumbnailImage: [getFileObjectFromUrl(caseData.thumbnailImage, { uid: caseId, name: caseData.title })]
  }

  const handleUpdate = async () => {
    try {
      const fieldsValue = await form.validateFields()
      await update({
        variables: {
          id: caseId,
          payload: getPayloadFromFieldsValue(fieldsValue as CaseFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.updateCase) {
            message.info('已更新見證 \u{1F9DA}')
            refetch()
            navigate('/dashboard/cases')
          } else {
            message.error('Oop！網址重複 \u{1F635}')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  const handleRemove = async () => {
    try {
      await remove({
        variables: { id: caseId },
        update: async (cache, { data }) => {
          if (data?.removeCase) {
            message.info('已刪除見證')
            navigate('/dashboard/cases')
          }
        }
      })
    } catch (e) {
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>編輯見證</Typography.Title>
      {loading || isNil(caseData) ? (
        <Skeleton active />
      ) : (
        <>
          <CaseForm form={form} initialValues={initialValues} setIsDirty={setIsDirty} />
          <ButtonRow>
            <Space>
              <Button type='primary' onClick={handleUpdate}>
                更新
              </Button>
              <Button>
                <LinkWithConfirm to='/dashboard/cases' isDirty={isDirty}>
                  回列表
                </LinkWithConfirm>
              </Button>
            </Space>
            <Popconfirm title='確定刪除見證？' onConfirm={handleRemove}>
              <Button type='primary' danger>
                刪除
              </Button>
            </Popconfirm>
          </ButtonRow>
        </>
      )}
    </>
  )
}

export default CasePage

import React, { FC } from 'react'
import styled from 'styled-components'

const WordsCalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

interface WordsCalProps {
  min: number
  max: number
  target?: number
  children?: any
}

const WordsCal: FC<WordsCalProps> = props => {
  const { min, max, target, children } = props

  const Green = (target && target > min && target < max) || target === min || target === max

  return (
    <>
      <WordsCalWrapper>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '20px' }}>
            建議字數 {min} ~ {max}
          </div>
          <div>目前字數 {Green ? <strong style={{ color: '#287c17' }}>{target}</strong> : <strong style={{ color: '#7c1717' }}>{target}</strong>}</div>
        </div>
        {children}
      </WordsCalWrapper>
    </>
  )
}

export default WordsCal

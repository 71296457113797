import { PaginationConfig } from 'antd/lib/pagination'
import { TableProps } from 'antd/lib/table'
import { complement, isNil, mergeAll, pickBy } from 'ramda'
import { NumberParam, useQueryParams } from 'use-query-params'

import { AntSorterType, FiltersParam, SorterParam, encodeSorter } from './paramTransformers'

interface GetAntdPaginationParams {
  page: number
  limit: number
  total: number
}
export const getAntdPagination = ({ page, total, limit }: GetAntdPaginationParams): PaginationConfig => ({
  current: page || 1,
  total,
  pageSize: limit,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} 項結果`
})

export interface Cursor {
  page?: number
  limit?: number
  sort?: string
}
const getSortQueryString = <SorterField>(sort: AntSorterType<SorterField> | undefined): string | undefined => sort && encodeSorter(sort)

const defaultPaginateQuery = {
  page: 1,
  limit: 100,
  sort: getSortQueryString({ columnKey: 'updated', order: 'descend' })
}

const useTableQuery = <FilterType, SorterField extends string>(initialPaginationQuery?: Cursor) => {
  const [tableQuery, setTableQuery] = useQueryParams({
    page: NumberParam,
    sort: SorterParam<SorterField>(),
    filters: FiltersParam<FilterType>()
  })

  const handleTableReset = () => {
    setTableQuery({}, 'push')
  }

  const handleTableChange: TableProps<any>['onChange'] = ({ current }, filters, sort) => {
    // @ts-ignore: 無法客製 antd filter field 的 type
    setTableQuery({
      page: current || 1,
      // @ts-ignore
      sort,
      // @ts-ignore
      filters
    })
  }

  const paginateState = pickBy<Cursor, Cursor>(complement(isNil), {
    page: tableQuery.page ?? undefined,
    sort: getSortQueryString<SorterField>(tableQuery.sort)
  })

  const cursor = mergeAll([defaultPaginateQuery, initialPaginationQuery || {}, paginateState])

  return {
    tableQuery, // antd type liked, for component
    cursor, // graphql query type liked, for query
    handleTableReset,
    handleTableChange
  }
}

export default useTableQuery

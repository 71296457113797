import { Tag as _Tag } from 'antd'
import { useIntl } from 'gatsby-plugin-intl'
import { isEmpty, isNil } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { Hsinchu, Taichung, Tainan, Taipei, Guting } from '../../../constants/newColor'
import { Location } from '../../../types/types'

const getPrefixColor = (commentLocation: Location) => {
  switch (commentLocation) {
    case Location.Taipei:
      return Taipei
    case Location.Guting:
      return Guting
    case Location.Hsinchu:
      return Hsinchu
    case Location.Taichung:
      return Taichung
    case Location.Tainan:
      return Tainan
    default:
      return ''
  }
}
const CustomizedTag = styled(_Tag)`
  margin-bottom: 8px;
  width: fit-content;
`
interface LocationLabelProps {
  value: Location
}
const LocationLabel = (props: LocationLabelProps) => {
  const { formatMessage } = useIntl()
  const { value } = props

  const isEmptyValue = isNil(value) || isEmpty(value)
  return isEmptyValue ? (
    <CustomizedTag color='grey'>未選取</CustomizedTag>
  ) : (
    <CustomizedTag color={getPrefixColor(value)}>{formatMessage({ id: `location.${value}` })}</CustomizedTag>
  )
}

export default LocationLabel

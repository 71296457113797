import { gql, useMutation } from '@apollo/client'
import { message } from 'antd'
import { navigate } from 'gatsby'
import React, { FC } from 'react'

import { LogoutMutation, LogoutMutationVariables } from '../types/types'

const logoutMutation = gql`
  mutation Logout {
    logout {
      data {
        id
        username
      }
      message
      success
    }
  }
`

interface LogoutButtonProps {
  isDirty: boolean
}

const LogoutButton: FC<LogoutButtonProps> = props => {
  const { isDirty } = props
  const [logout] = useMutation<LogoutMutation, LogoutMutationVariables>(logoutMutation)

  const handleLogout = async () => {
    try {
      await logout({
        update: (cache, result) => {
          if (result.errors) {
            message.error(`登出時發生錯誤: ${result?.errors?.join(' ')}`)
            return
          }
          if (!result.data?.logout?.success) {
            message.error(`登出時發生錯誤: ${result.data?.logout?.message}`)
            return
          }
          message.success(`${result.data?.logout?.data?.username} 已登出`)
          navigate('/login')
        }
      })
    } catch (e) {
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  const handleClick = () => {
    if (isDirty) {
      const confirmed = window.confirm(
        '系統可能不會儲存你所做的變更。 要離開頁面嗎?'
      )
      if (confirmed) {
        handleLogout()
      }
    } else {
      handleLogout()
    }
  }

  return <div onClick={handleClick}>登出</div>
}

export default LogoutButton

import { Button as AntdButton, Radio } from 'antd'
import { useIntl } from 'gatsby-plugin-intl'
import { isEmpty, isNil } from 'ramda'
import React, { useState } from 'react'
import { Control, useController, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { CommentStatus } from '../../../types/types'

const getPrefixColor = (commentStatus: CommentStatus) => {
  switch (commentStatus) {
    case CommentStatus.Pending:
      return '#F4A460'
    case CommentStatus.InProcess:
      return '#6495ED'
    case CommentStatus.Evaluated:
      return '#52C41A'
    default:
      return ''
  }
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Button = styled(AntdButton)``
interface RadioProps {
  display: boolean
}
const RadioWrapper = styled.div<RadioProps>`
  display: ${props => (props.display ? 'block' : 'none')};
`
interface SelectorProps {
  display: boolean
  onClick: (value: any) => void
  control: Control
  defaultValue: CommentStatus
}
const StatusSelector = (props: SelectorProps) => {
  const { display, onClick, control, defaultValue } = props
  const { formatMessage } = useIntl()

  const {
    field: { onChange: controllerOnChange, value: status }
  } = useController({ name: 'status', control })
  const valueNotChange = isNil(status) || isEmpty(status)
  return (
    <RadioWrapper display={display}>
      <Radio.Group
        defaultValue={defaultValue}
        value={status}
        onChange={value => {
          controllerOnChange(value)
        }}
      >
        {Object.values(CommentStatus).map((status, index) => (
          <Radio key={index} value={status} style={{ marginBottom: '4px' }}>
            {formatMessage({ id: `status.${status}` })}
          </Radio>
        ))}
      </Radio.Group>
      <Button onClick={() => onClick(valueNotChange ? defaultValue : status)}>確認</Button>
    </RadioWrapper>
  )
}

interface StatusProps {
  commentStatus: CommentStatus
  display: boolean
}

const Wrapper = styled.button<StatusProps>`
  display: ${props => (props.display ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 16px;
  color: #fff;
  font-size: 12px;

  background: ${props => getPrefixColor(props.commentStatus)};
  &:hover {
    filter: opacity(75%);
  }
`

interface CommentStatusLabelProps {
  value: CommentStatus
  handleSubmit: (formValues: any) => void
}
type props = CommentStatusLabelProps

const CommentStatusLabel = (props: props) => {
  const { value, handleSubmit } = props
  const { formatMessage } = useIntl()
  const { control } = useForm()

  const isEmptyValue = isNil(value) || isEmpty(value)
  const defaultValue = isEmptyValue ? CommentStatus.Pending : value
  const [status, setStatus] = useState(defaultValue)
  const [selectorDisplay, setSelectorDisplay] = useState(false)
  const [statusDisplay, setStatusDisplay] = useState(true)

  const sectionDisplay = () => {
    setSelectorDisplay(true)
    setStatusDisplay(false)
  }

  const onClick = status => {
    setSelectorDisplay(false)
    setStatusDisplay(true)
    setStatus(status)
    handleSubmit(status)
  }
  return (
    <SectionWrapper>
      <Wrapper commentStatus={status} onClick={sectionDisplay} display={statusDisplay}>
        {formatMessage({ id: `status.${status}` })}
      </Wrapper>
      <StatusSelector display={selectorDisplay} onClick={onClick} control={control} defaultValue={defaultValue} />
    </SectionWrapper>
  )
}

export default CommentStatusLabel
